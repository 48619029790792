


































































































/**
 * Default search options can be passed as a component.
 *
 * Search results are passed to the default slot for custom rendering in the
 * parent component.
 *
 * A v-if must be added to the search results (default) slot to check that search
 * results exist.
 */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";
import AppLoading from "@/components/core/AppLoading.vue";
import AppPagination from "@/components/core/AppPagination.vue";
import AppRecordCount from "@/components/core/AppRecordCount.vue";
import { SearchOptions, SearchRequest } from "@/utils/api";
import { Notification, createErrorNotification } from "@/utils/notification";

@Component({
  components: {
    AppAlert,
    AppButton,
    AppInput,
    AppInputGroup,
    AppLoading,
    AppPagination,
    AppRecordCount,
  },
})
export default class AppSearch extends Vue {
  @Prop({ type: Function, required: true })
  readonly searchFunc!: SearchRequest;

  @Prop({ required: false })
  readonly catalogId!: pro.Id;

  @Prop({ required: false })
  readonly placeholder!: string;

  @Prop({ type: Boolean, default: true })
  readonly allowQueries!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly changes!: boolean;

  @Prop({ type: String, required: false })
  readonly orderBy!: string;

  @Prop({ type: Number, default: 10 })
  readonly limit!: number;

  @Prop({ type: Object, default: () => ({}) })
  readonly filters!: unknown;

  @Watch("filters", { deep: true })
  onFiltering(): void {
    this.search();
  }

  get hasSearchBarSlot(): boolean {
    return !!this.$slots["searchBar"];
  }

  created() {
    this.search();
  }

  q: string | null = null;
  offset = 0;
  searchResults: pro.SearchResults | null = null;
  notification: Notification | null = null;

  async search(): Promise<void> {
    try {
      this.notification = this.searchResults = null;
      this.searchResults = await this.searchFunc(
        this.searchOptions(),
        this.catalogId
      );
      this.$emit("search");
    } catch (err) {
      this.notification = createErrorNotification(
        "Error",
        "Unable to load results."
      );
    }
  }

  searchOptions(): SearchOptions {
    return {
      limit: this.limit,
      offset: this.offset,
      filters: this.filters,
      ...(this.orderBy && { orderBy: this.orderBy }),
      ...(this.q && { q: this.q }),
    };
  }

  async clearQuery() {
    this.q = null;
    await this.search();
  }

  goToPage(page: number) {
    if (page < 2) this.offset = 0;
    else this.offset = this.limit * (page - 1);
    this.search();
  }

  firstRecord(): number {
    if (!this.searchResults) return 0;
    else if (this.searchResults.page === 1) return 1;
    else if (this.searchResults.hitsPerPage === 1)
      return this.searchResults.page;
    return this.searchResults.hitsPerPage * (this.searchResults.page - 1) + 1;
  }

  lastRecord(): number {
    if (!this.searchResults) return 0;
    else if (this.searchResults.page === this.searchResults.nbPages)
      return this.searchResults.nbHits;
    return this.firstRecord() + this.searchResults.hitsPerPage - 1;
  }
}





















































import { Component, Vue } from "vue-property-decorator";
import AppBadge from "@/components/core/AppBadge.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppSearch from "@/components/core/AppSearch.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { isSuperUser } from "@/utils/authentication";
import { searchCatalogs } from "@/utils/api";

@Component({
  components: {
    AppBadge,
    AppListGroup,
    AppListGroupItem,
    AppSearch,
    AppWidget,
  },
  methods: { searchCatalogs },
})
export default class AppCatalogListWidget extends Vue {
  visibleCatalogs(availableCatalogs: pro.Catalog[]): pro.Catalog[] {
    if (isSuperUser()) return availableCatalogs;
    return availableCatalogs.filter(
      (catalog: pro.Catalog) =>
        !catalog.disabled || (catalog.roles && catalog.roles.includes("ADMIN"))
    );
  }
}






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppRecordCount extends Vue {
  @Prop({ type: Number, required: true })
  readonly firstRecord!: number;

  @Prop({ type: Number, required: true })
  readonly lastRecord!: number;

  @Prop({ type: Number, required: true })
  readonly totalRecords!: number;

  get recordCount(): string {
    return `Showing results ${this.firstRecord} to ${this.lastRecord} of ${this.totalRecords}`;
  }
}

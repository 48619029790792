















































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppCatalogCreateModal from "@/components/modals/AppCatalogCreateModal.vue";
import AppCatalogListWidget from "@/components/widgets/AppCatalogListWidget.vue";
import AppOrderList from "@/components/AppOrderList.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { isSuperUser } from "@/utils/authentication";

@Component({
  components: {
    AppButton,
    AppCatalogCreateModal,
    AppCatalogListWidget,
    AppOrderList,
    TheHeader,
    TheMain,
  },
  methods: { isSuperUser },
})
export default class Home extends Vue {
  showCatalogCreateModal = false;
}

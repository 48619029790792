






















































































import { Component, Prop, Vue } from "vue-property-decorator";
import AppBadge from "@/components/core/AppBadge.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppListGroup from "@/components/core/AppListGroup.vue";
import AppListGroupItem from "@/components/core/AppListGroupItem.vue";
import AppSearch from "@/components/core/AppSearch.vue";
import AppStartOrderModal from "@/components/modals/AppStartOrderModal.vue";
import { myOrders, searchOrders } from "@/utils/api";
import { fromNow } from "@/utils/date";

@Component({
  components: {
    AppBadge,
    AppButton,
    AppListGroup,
    AppListGroupItem,
    AppSearch,
    AppStartOrderModal,
  },
  methods: { fromNow },
})
export default class AppOrderList extends Vue {
  @Prop({ type: [String, Number], required: false })
  readonly catalogId!: pro.Id;

  /**
   * Defines the type of search. You can either search for the Current User's
   * Orders or a Catalog's orders.
   */
  @Prop({ type: String, required: true })
  readonly listType!: "currentUser" | "catalog";

  @Prop({ type: Boolean, default: false })
  readonly hideCreateButton!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly filterCompletedOrders!: boolean;

  get searchFunc() {
    if (this.listType == "currentUser") return myOrders;
    else return searchOrders;
  }

  showStartOrderModal = false;
}

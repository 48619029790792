














































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";

@Component({ components: { AppButton } })
export default class AppModal extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: String, required: false })
  readonly type!: "error" | "success" | "neutral";

  @Prop({ default: "auto" })
  readonly size!: "auto" | "sm" | "lg";

  @Prop({ type: Boolean, default: false })
  readonly hideCancelButton!: boolean;

  @Prop({ type: String, default: "Close" })
  readonly cancelButtonText!: string;

  close() {
    this.$emit("close");
  }

  hasHeader(): boolean {
    return !!this.$slots["modal-header"];
  }

  sizeClass(): string {
    switch (this.size) {
      case "sm":
        return "max-w-screen-sm";
      case "lg":
        return "max-w-screen-lg";
      case "auto":
      default:
        return "sm:w-auto";
    }
  }

  typeClass(): void | string {
    if (!this.type) return;
    return `modal-${this.type}`;
  }

  mounted() {
    document.querySelector("body")?.classList.add("modal-open");
  }

  beforeDestroy() {
    document.querySelector("body")?.classList.remove("modal-open");
  }
}

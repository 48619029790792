









































import { Component, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCountdown from "@/components/core/AppCountdown.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppModal from "@/components/core/AppModal.vue";
import { createCatalog } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppAlert,
    AppButton,
    AppCountdown,
    AppLabel,
    AppModal,
  },
})
export default class AppCatalogCreateModal extends Vue {
  catalog: pro.Catalog = {
    disabled: true,
    name: "",
  };
  notification: Notification | null = null;
  saving = false;

  async createCatalog(): Promise<void> {
    try {
      const catalog = await createCatalog(this.catalog);
      this.$router.push({
        name: "CATALOG",
        params: { catalogId: String(catalog.id) },
      });
    } catch (err) {
      this.notification = createErrorNotification("Error", err.message);
    }
  }
}































































import { Component, Prop, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppLoading from "@/components/core/AppLoading.vue";
import AppModal from "@/components/core/AppModal.vue";
import AppSelect from "@/components/core/AppSelect.vue";
import { searchCatalogs } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppAlert,
    AppButton,
    AppLoading,
    AppModal,
    AppSelect,
  },
})
export default class AppStartOrderModal extends Vue {
  catalogId: pro.Id = 0;
  userCatalogs: pro.Catalog[] = [];
  loading = false;
  notification: Notification | null = null;

  @Prop({ type: Boolean, default: true })
  readonly closable!: boolean;

  async mounted() {
    await this.loadCatalogs();
  }

  async loadCatalogs(): Promise<void> {
    this.notification = null;
    this.loading = true;

    try {
      const search = await searchCatalogs({ limit: 1000 });
      this.userCatalogs = search.hits as pro.Catalog[];
    } catch (err) {
      this.notification = createErrorNotification(
        "Fail",
        "We couldn't load a list of your catalogs. Please try again later."
      );
    } finally {
      this.loading = false;
    }
  }

  findSelectedCatalog(): pro.Catalog {
    // Convert observed object for Safari
    const userCatalogs = this.userCatalogs.map(catalog => ({ ...catalog }));
    const catalog = userCatalogs.find(catalog => catalog.id === this.catalogId);
    if (!catalog) throw new Error("Could not find requested Catalog.");
    return catalog;
  }

  requestableCatalogs(): pro.Catalog[] {
    return this.userCatalogs.filter(catalog => !catalog.disabled);
  }

  startOrder() {
    this.$router.push({
      name: "CATALOG",
      params: { catalogId: String(this.catalogId) },
    });
  }
}

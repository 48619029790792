





































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class AppPagination extends Vue {
  @Prop({ type: Number, required: true })
  readonly currentPage!: number;

  @Prop({ type: Number, required: true })
  readonly totalPages!: number;

  goTo(val: number) {
    this.$emit("goTo", this.currentPage + val);
  }

  canGoBackTwoPages() {
    return this.isLastPage() && this.totalPages >= 3;
  }

  canGoForwardTwoPages() {
    return this.isFirstPage() && this.totalPages >= 3;
  }

  isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  isLastPage(): boolean {
    return this.currentPage === this.totalPages;
  }
}
